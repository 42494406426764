.lightbox {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.8);
    align-items: center;
    justify-content: center;
    display: flex;
    display: none;
    opacity: 0;
    z-index: 999;
    padding: 80 / $setFs + rem 0;
    box-sizing: border-box;
    overflow: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;

    &::-webkit-scrollbar {
        width: 4 / $setFs + rem;
    }

    &::-webkit-scrollbar-track {
        border-radius: 4 / $setFs + rem;
        background: rgba(0, 0, 0, 0.03);
    }

    &::-webkit-scrollbar-thumb {
        background: rgba(0, 0, 0, 0.1);
    }

    .container {
        $calcW: 80 / $setFs + rem;
        max-width: none;
        width: calc(100% - #{$calcW});
        height: 100%;
        box-sizing: border-box;

        @media screen and (max-width: 768px) {
            width: auto;
        }
    }
}

.lb_board {
    $calcW: 80 / $setFs + rem;
    width: calc(100% - #{$calcW});
    max-width: 1200 / $setFs + rem;
    margin: auto;
    position: relative;

    @media screen and (max-width: 768px) {
        $calcW: 40 / $setFs + rem;
        width: calc(100% - #{$calcW});
        max-width: none;
        padding: 0;
    }

    @media screen and (max-width: 480px) {
        $calcW: 20 / $setFs + rem;
        width: calc(100% - #{$calcW});
    }
}

.btn_close {
    position: absolute;
    top: 20 / $setFs + rem;
    right: 20 / $setFs + rem;
    z-index: 5;

    @media screen and (max-width: 480px) {
        right: 10 / $setFs + rem;
    }
}

.btn_close_link {
    width: 50 / $setFs + rem;
    height: 50 / $setFs + rem;
    border-radius: 50 / $setFs + rem;
    background: $color2;

    &:before,
    &:after {
        content: "";
        display: block;
        width: 26 / $setFs + rem;
        height: 3 / $setFs + rem;
        background: #fff;
        position: absolute;
        left: 50%;
        top: 50%;
        margin-left: -13 / $setFs + rem;
        margin-top: -1.5 / $setFs + rem;
        transition: all 0.4s ease-out;
    }

    &:before {
        transform: rotate(-45deg);
    }

    &:after {
        transform: rotate(45deg);
    }

    &:hover {
        &:before {
            transform: rotate(45deg);
        }

        &:after {
            transform: rotate(-45deg);
        }
    }
}

.lb_btn {
    cursor: pointer;
}


#lb_award {
    display: flex;
    opacity: 1;

    .maintitle_row {
        text-align: center;
        position: relative;
        top: -60 / $setFs + rem;
    }

    .lb_award_txt {
        text-align: center;
        font-size: 28 / $setFs + rem;
        margin-top: 10 / $setFs + rem;
    }
}