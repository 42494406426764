$winSize: 1920;

html {
    width: 100%;
    font-family: $font;
    color: $txtColor;
    font-size: $setFs/$winSize * 100vw;
    position: relative;
    overflow-x: hidden;

    @media screen and (max-width: 850px) {
        $winSize: 1000;
        font-size: $setFs/$winSize * 100vw;
    }

    @media screen and (max-width: 768px) {
        $winSize: 950;
        font-size: $setFs/$winSize * 100vw;
    }

    @media screen and (max-width: 480px) {
        $winSize: 620;
        font-size: $setFs/$winSize * 100vw;
    }
}

.en {
    letter-spacing: 0;
}

body {
    @extend html;
}

.wrapper {
    width: 100%;
    min-height: 100vh;
    background: #fff;
    line-height: 1.6;
    position: relative;
    overflow: hidden;
    z-index: 1;
}

.container {
    $x: 40 / $setFs + rem;
    width: calc(100% - #{$x});
    max-width: 1190 / $setFs + rem;
    position: relative;
    margin: 0 auto;
    z-index: 1;

    @media screen and (max-width: 850px) {
        max-width: 1000 / $setFs + rem;
    }
}

.container_inner {
    $x: 80 / $setFs + rem;
    width: calc(100% - #{$x});
    max-width: 1030 / $setFs + rem;
    position: relative;
    left: 15 / $setFs + rem;
    margin: 0 auto;
    z-index: 1;

    @media screen and (max-width: 850px) {
        $x: 60 / $setFs + rem;
        width: calc(100% - #{$x});
    }
}

section {
    position: relative;
}