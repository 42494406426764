.accesskey {
    font-size: 16 / $setFs + rem;
    color: transparent;
    position: absolute;
    right: 100%;
    top: 0;
    margin-right: 10 / $setFs + rem;
    z-index: 1;
    @include acc;

    @media screen and (max-width: 768px) {
        display: none;
    }
}

.access_content {
    width: 1 / $setFs + rem;
    height: 1 / $setFs + rem;
    font-size: 16 / $setFs + rem;
    padding: 0 10 / $setFs + rem;
    overflow: hidden;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 10000;
    clip: rect(0, 0, 0, 0);
    @include acc;

    @media screen and (max-width: 768px) {
        display: none;
    }

    &:focus {
        width: auto;
        height: auto;
        overflow: visible;
        clip: auto;
    }
}

.sr-only {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1 / $setFs + rem;
    margin: -1 / $setFs + rem;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1 / $setFs + rem;
}

.img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
}

:focus,
button:focus {
    outline: 0;
}

svg {
    display: block;
    width: 100%;
    height: 100%;
}

a {
    display: block;
    text-decoration: none;
    transition: all 0.2s ease-out;
    color: inherit;
    box-sizing: border-box;
    position: relative;

    &:hover {
        color: inherit;
        text-decoration: none;
    }
}


.hide {
    display: none;
}

.clear {
    &:before {
        content: "";
        display: table;
    }

    &:after {
        @extend :before;
        clear: both;
    }
}

.c {
    clear: both;
}


img {
    width: 100%;
    height: auto;
    display: block;
}

iframe {
    width: 100%;
    height: auto;
}

table {
    width: 100%;

    th,
    td {
        vertical-align: middle;
    }
}

.table {
    width: 100%;
    display: table;
    table-layout: fixed;
}

.tr {
    display: table-row;
}

.td {
    display: table-cell;
    vertical-align: middle;
    position: relative;
}

.th {
    display: table-cell;
    vertical-align: middle;
    position: relative;
}

.inblock {
    font-size: 0;
    letter-spacing: 0;

    >* {
        display: inline-block;
        vertical-align: middle;
        letter-spacing: 1 / $setFs + rem;
    }
}