.loading {
    width: 100%;
    height: 100%;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
}

.loader_container {
    position: relative;
}

.loading_title {
    width: 224px;
    margin: 0 auto;
    margin-bottom: 20px;
}

.loading_txt {
    text-align: center;
    color: $color3;
    font-size: 18 / $setFs + rem;
    letter-spacing: 1 / $setFs + rem;
    animation: loading_txt 1.5s alternate infinite ease-in-out;
}

@keyframes loading_txt {

    50% {
        opacity: 0.2;
    }
}