.breadcrumbs {
    margin-bottom: 50 / $setFs + rem;

    ul {
        display: flex;
        flex-wrap: wrap;
        margin-left: -14 / $setFs + rem;
        margin-right: -14 / $setFs + rem;
    }

    li {
        font-size: 14 / $setFs + rem;
        line-height: 1.5;
        padding: 0 14 / $setFs + rem;
        position: relative;

        &::after {
            content: '/';
            opacity: .7;
            position: absolute;
            top: 50%;
            right: 0;
            transform: translate(50%, -53%);
        }

        &:last-child {
            &::after {
                display: none;
            }
        }
    }

    a {
        @include acc;

        &::before {
            content: '';
            width: 100%;
            height: 1px;
            background: #000;
            position: absolute;
            bottom: 0;
            left: 0;
            transform: scale(0);
            transition: transform .2s ease-out;
        }

        &:focus,
        &:hover {
            &::before {
                transform: scale(1);
            }
        }
    }

    .fa-magnifying-glass {
        display: none;
    }
}