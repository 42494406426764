@import "../share/variable";
@import "../share/common";
@import "../share/components/input";
@import "../share/components/button";
@import "../share/components/auth_row";
@import "../share/components/submission";
@import "../share/components/maintitle";
@import "../share/components/swiper";

.mapimg {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.5;
}

#scene {
    width: 100%;
    pointer-events: unset !important;
    position: relative;

    @media screen and (max-width: 950px) {
        width: 220%;
        left: 150%;
        transform: translateX(-100%) !important;
    }
}

.scene_layer {
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
}

.scene_block {
    @media screen and (max-width: 950px) {
        height: calc(100vh - 10rem);
        object-fit: cover;
        object-position: 150% top;
    }

    @media screen and (max-width: 480px) {
        height: calc(100vh - 10rem);
        object-position: 98% top;
    }
}

.kv_peo {
    width: 209 / 1920 * 100%;
    position: absolute;
    right: 35.8%;
    top: 16%;

    @media screen and (max-width: 950px) {
        width: 10.5%;
        right: 55.8%;
        top: 17%;
    }

    @media screen and (max-width: 480px) {
        width: 12%;
        right: 54%;
    }
}

.kv_main {
    width: 774 / 1920 * 100%;
    position: absolute;
    left: 8.8%;
    top: 33%;

    @media screen and (max-width: 950px) {
        width: 36%;
        left: 54.6%;
        transform: translateX(-50%);
    }

    @media screen and (max-width: 480px) {
        width: 42%;
        left: 54.8%;
        transform: translateX(-50%);
    }
}

.kv_logo {
    width: 224 / $setFs + rem;
    position: absolute;
    top: 120 / $setFs + rem;
    right: 57 / $setFs + rem;

    @media screen and (max-width: 950px) {
        width: 10%;
        top: 11%;
        right: 50%;
        transform: translateX(56.8vw);
    }

    @media screen and (max-width: 480px) {
        width: 12%;
    }
}

.kv_txt {
    width: 223 / $setFs + rem;
    position: absolute;
    left: 121 / $setFs + rem;
    bottom: 6 / $setFs + rem;

    @media screen and (max-width: 950px) {
        width: 12%;
        bottom: 15 / $setFs + rem;
        left: 50%;
        transform: translateX(-35vw);
    }

    @media screen and (max-width: 480px) {
        width: 15%;
    }
}

.kv_air {
    width: 362 / 1920 * 100%;
    position: absolute;
    bottom: 0;
    right: 0;

    @media screen and (max-width: 950px) {
        bottom: -0.5%;
        right: 20.9%;
    }
}

.kv_airplane_1 {
    width: 226 / 1920 * 100%;
    position: absolute;
    top: 9.5%;
    right: 21%;

    @media screen and (max-width: 950px) {
        top: 13%;
        right: 36%;
    }

    @media screen and (max-width: 480px) {
        top: 13.5%;
        right: 38%;
    }
}

.kv_airplane_2 {
    width: 1520 / 1920 * 100%;
    position: absolute;
    bottom: 15%;
    left: 0;

    @media screen and (max-width: 950px) {
        width: 18%;
        bottom: 10%;
        left: 30%;
    }

    @media screen and (max-width: 480px) {
        width: 20%;
        bottom: 8%;
    }
}

.kv_btn {
    width: 348 / $setFs + rem;
    box-shadow: 7 / $setFs + rem 8 / $setFs + rem 0 rgba(#000, 0.1);
    margin: 0 auto;
    margin-top: 53 / $setFs + rem;

    @media screen and (max-width: 480px) {
        margin-top: 70 / $setFs + rem;
    }
}

.kv_btn_link {
    font-size: 38 / $setFs + rem;
    color: #fff;
    letter-spacing: 2 / $setFs + rem;
    line-height: 74 / $setFs + rem;
    border: 2 / $setFs + rem solid #fff;
    background: #af323a;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 480px) {
        font-size: 36 / $setFs + rem;
    }

    &:hover {
        color: #fff;
        background: lighten(#af323a, 10%);
    }
}

.icon_arrow_r {
    width: 17 / $setFs + rem;
    margin-left: 25 / $setFs + rem;
    animation: icon_arrow_r 0.8s ease-in-out infinite;
}

@keyframes icon_arrow_r {
    50% {
        transform: translateX(#{5 / $setFs + rem});
    }
}

@keyframes scrolldown {
    50% {
        opacity: 0.3;
    }
}

.scrolldown {
    width: 1 / $setFs + rem;
    height: 34 / $setFs + rem;
    position: absolute;
    bottom: 0;
    left: 50%;
    animation: scrolldown 1.5s alternate infinite ease-in-out;

    &::before,
    &::after {
        content: "";
        width: 1 / $setFs + rem;
        height: 28 / $setFs + rem;
        background: #fff;
        position: absolute;
        top: 6 / $setFs + rem;
        left: 0;
    }

    &::before {
        height: 56 / $setFs + rem;
        background: #80bced;
    }
}

.scrolldown_txt {
    font-size: 20 / $setFs + rem;
    color: #fff;
    transform: rotate(-90deg);
    transform-origin: left center;
    position: absolute;
    left: 0;
    bottom: calc(100% - #{10 / $setFs + rem});
}

.about_sec {
    padding-top: 65 / $setFs + rem;

    @media screen and (max-width: 850px) {
        padding-bottom: 130 / $setFs + rem;
    }

    .container {
        @media screen and (max-width: 480px) {
            width: 100%;
        }
    }

    .maintitle {
        color: #fff;

        &::before {
            background-image: url("../../img/title_deco_w.png");
        }

        &::after {
            background-image: url("../../img/title_deco_line_w.png");
        }
    }
}

.about_bg_1 {
    width: 308 / $setFs + rem;
    position: absolute;
    top: 0;
    left: 0;
}

.about_bg_2 {
    width: 321 / $setFs + rem;
    position: absolute;
    top: 0;
    right: 0;
}

.about_house_body {
    background: $color2;
    border-bottom-left-radius: 15 / $setFs + rem;
    border-bottom-right-radius: 15 / $setFs + rem;
    margin-top: -112 / $setFs + rem;
    padding-bottom: 62 / $setFs + rem;
    padding-bottom: 92 / $setFs + rem;

    @media screen and (max-width: 850px) {
        margin-top: -60 / $setFs + rem;
    }

    @media screen and (max-width: 480px) {
        margin-top: 0 / $setFs + rem;
    }

    .container {
        width: calc(100% - #{183 / $setFs + rem});

        @media screen and (max-width: 850px) {
            $x: 40 / $setFs + rem;
            width: calc(100% - #{$x});
            max-width: 1000 / $setFs + rem;
        }
    }
}

.about_txt {
    color: #fff;
    font-size: 20 / $setFs + rem;
    line-height: 1.5;
    text-align: center;
    margin-top: 30 / $setFs + rem;
}

.video {
    width: 100%;
    max-width: 860 / $setFs + rem;
    margin: 25 / $setFs + rem auto 0;
    position: relative;
}

.about_btn_more {
    width: 181 / $setFs + rem;
    position: absolute;
    bottom: 50 / $setFs + rem;
    right: -90 / $setFs + rem;

    @media screen and (max-width: 850px) {
        position: relative;
        bottom: auto;
        right: auto;
        margin: 30 / $setFs + rem auto -180 / $setFs + rem;
    }
}

.about_btn_more_txt {
    font-size: 18 / $setFs + rem;
    color: #fff;
    font-weight: bold;
    letter-spacing: 2 / $setFs + rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.about_btn_more_bg {
    animation: about_btn_more_bg 20s linear infinite;
}

@keyframes about_btn_more_bg {
    100% {
        transform: rotate(360deg);
    }
}

.regulation_sec {
    background: $color;
    margin-top: -24 / $setFs + rem;

    .container {
        padding-top: 55 / $setFs + rem;
    }

    .maintitle_row {
        margin-bottom: 50 / $setFs + rem;
    }
}

.subtitle_row {
    display: flex;
    justify-content: center;
    position: relative;
}

.subtitle {
    font-size: 22 / $setFs + rem;
    color: #fff;
    letter-spacing: 2 / $setFs + rem;
    background: #333;
    line-height: 40 / $setFs + rem;
    padding: 0 34 / $setFs + rem;
    position: relative;
    z-index: 1;

    &::before,
    &::after {
        content: "";
        border-style: solid;
        border-width: 21 / $setFs + rem 5 / $setFs + rem;
        position: absolute;
        top: 0;
    }

    &::before {
        border-color: transparent #333 #333 transparent;
        right: 100%;
    }

    &::after {
        border-color: #333 transparent transparent #333;
        left: 100%;
    }
}

.line {
    width: calc(100% - #{42 / $setFs + rem});
    height: 7 / $setFs + rem;
    background-image: url("../../img/line.png");
    background-repeat: repeat-x;
    background-size: auto 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%);
    margin-top: -4 / $setFs + rem;

    &::before,
    &::after {
        content: "";
        width: 21 / $setFs + rem;
        height: 19 / $setFs + rem;
        background-image: url("../../img/line_corner.png");
        background-size: auto 100%;
        position: absolute;
        top: 0;
    }

    &::before {
        right: 100%;
    }

    &::after {
        left: 100%;
        transform: scaleX(-1);
    }
}

.regulation_list {
    $x: 40 / $setFs + rem;
    width: calc(100% - #{$x});
    max-width: 996 / $setFs + rem;
    position: relative;
    margin: 23 / $setFs + rem auto 0;
    z-index: 1;
}

.regulation_li {
    font-size: 20 / $setFs + rem;
    letter-spacing: 1 / $setFs + rem;
    line-height: 1.7;
    padding-left: 20 / $setFs + rem;
    position: relative;

    &::before {
        content: "";
        width: 7 / $setFs + rem;
        height: 7 / $setFs + rem;
        border-radius: 7 / $setFs + rem;
        background: #333;
        position: absolute;
        top: 15 / $setFs + rem;
        left: 3 / $setFs + rem;
    }

    &:last-child {
        &::before {
            top: 30 / $setFs + rem;

            @media screen and (max-width: 480px) {
                top: 0.8rem;
            }
        }
    }
}

.regulation_row {
    display: flex;

    @media screen and (max-width: 480px) {
        display: block;
    }
}

.regulation_left {
    font-weight: bold;
    white-space: nowrap;

    &.mt {
        margin-top: 17 / $setFs + rem;
    }
}

.regulation_right {
    padding-left: 12 / $setFs + rem;

    @media screen and (max-width: 480px) {
        padding-left: 0;
    }
}

.steps_num {
    width: 30 / $setFs + rem;
    height: 30 / $setFs + rem;
    border-radius: 30 / $setFs + rem;
    background: #333;
    color: #fff;
    font-weight: bold;
    font-size: 18 / $setFs + rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(-50%, -50%);

    &:before {
        content: "";
        width: 1 / $setFs + rem;
        height: 50 / $setFs + rem;
        background: #333;
        position: absolute;
        top: 100%;
        left: 50%;
        z-index: -1;

        @media screen and (max-width: 480px) {
            height: 120 / $setFs + rem;
        }
    }
}

.regulation_steps_li {
    letter-spacing: 0;
    border-radius: 100 / $setFs + rem;
    background: #fff;
    padding: 10 / $setFs + rem 30 / $setFs + rem;
    position: relative;
    margin: 10 / $setFs + rem 0;

    @media screen and (max-width: 480px) {
        padding: 20 / $setFs + rem 30 / $setFs + rem;
        padding-left: 40 / $setFs + rem;
    }

    &:last-child {
        .steps_num {
            &:before {
                display: none;
            }
        }
    }

    .regulation_right {
        padding-left: 0;
    }
}

.regulation_content {
    padding-bottom: 38 / $setFs + rem;

    &:last-child {
        padding-bottom: 0;
    }
}

.award_row,
.award_col {
    display: flex;
    align-items: center;
}

.award_row {
    margin-left: -24 / $setFs + rem;
    margin-right: -24 / $setFs + rem;
    margin-top: 40 / $setFs + rem;

    @media screen and (max-width: 850px) {
        flex-direction: column;
    }
}

.award_col {
    padding: 0 30 / $setFs + rem;

    @media screen and (max-width: 850px) {
        width: 100%;
        justify-content: center;
        padding: 20 / $setFs + rem 0;
    }
}

.award_item {
    flex: 0 0 215 / $setFs + rem;
    position: relative;
    z-index: 1;
}

.award_caption {
    padding-left: 22 / $setFs + rem;

    @media screen and (max-width: 850px) {
        flex: 0 0 21rem;
    }
}

.award_title {
    font-size: 24 / $setFs + rem;
    letter-spacing: 1 / $setFs + rem;
    position: relative;
    padding-bottom: 10 / $setFs + rem;

    &:after {
        content: "";
        width: calc(100% + #{30 / $setFs + rem});
        height: 1 / $setFs + rem;
        background: $txtColor;
        position: absolute;
        bottom: 0;
        right: 0;
    }
}

.award_txt {
    font-size: 20 / $setFs + rem;
    white-space: nowrap;
    line-height: 1.5;
    padding-top: 15 / $setFs + rem;
}

.schedule_sec {
    padding-top: 38 / $setFs + rem;
    padding-bottom: 5 / $setFs + rem;

    @media screen and (max-width: 850px) {
        width: 70%;
        padding-top: 70 / $setFs + rem;
        margin: 0 auto;
    }

    @media screen and (max-width: 480px) {
        width: 100%;
    }

    .maintitle_row {
        margin-top: -14 / $setFs + rem;
        margin-bottom: 25 / $setFs + rem;

        @media screen and (max-width: 850px) {
            margin-bottom: 40 / $setFs + rem;
        }
    }
}

.schedule_step {
    position: relative;

    .hvr {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        transition: opacity 0.1s ease-out;
    }

    &.active {
        .hvr {
            opacity: 1;
        }

        .step_car {
            opacity: 1;
        }
    }
}

.step_car {
    width: 194 / $setFs + rem;
    position: absolute;
    top: -3 / $setFs + rem;
    left: 365 / $setFs + rem;
    opacity: 0;
    transform: translateX(#{-600 / $setFs + rem});

    @media screen and (max-width: 850px) {
        width: 11rem;
        top: -1.5rem;
        left: -2rem;
    }

    @media screen and (max-width: 480px) {
        width: 9rem;
        top: -0.4rem;
        left: 0.8rem;
    }
}

.step_car_img {
    animation: step_car 0.2s ease-out infinite;
}

@keyframes step_car {
    50% {
        transform: translateY(-1px);
    }

    100% {
        transform: translateY(0);
    }
}

.schedule_note {
    font-size: 18 / $setFs + rem;
    text-align: center;
    letter-spacing: 0.5 / $setFs + rem;
    margin-top: 25 / $setFs + rem;
}

.eligibility_sec {
    background: $color;
    margin-top: -30 / $setFs + rem;
    padding-bottom: 6rem;

    .container {
        padding-top: 55 / $setFs + rem;

        @media screen and (max-width: 850px) {
            padding-bottom: 55 / $setFs + rem;
        }
    }

    .maintitle_row {
        margin-bottom: 55 / $setFs + rem;
    }
}

.eligibility_note {
    font-size: 20 / $setFs + rem;
    text-align: center;
    margin-top: 18 / $setFs + rem;
}

.eligibility_content {
    padding-bottom: 48 / $setFs + rem;

    &:last-child {
        padding-bottom: 0;
    }
}

.eligibility_row {
    display: flex;
    margin-top: 20 / $setFs + rem;
}

.row_1 {
    @media screen and (max-width: 850px) {
        flex-direction: column;
    }

    .eligibility_col {
        padding: 0 40 / $setFs + rem;

        @media screen and (max-width: 850px) {
            justify-content: center;
            padding: 15 / $setFs + rem;
        }
    }
}

.row_2 {
    justify-content: space-between;

    @media screen and (max-width: 850px) {
        flex-wrap: wrap;
        justify-content: center;
    }

    .eligibility_col {
        @media screen and (max-width: 850px) {
            padding: 5 / $setFs + rem 15 / $setFs + rem;
        }
    }
}

.eligibility_col {
    display: flex;
    align-items: center;
}

.eligibility_icon {
    width: 128 / $setFs + rem;
    height: 128 / $setFs + rem;
    border-radius: 128 / $setFs + rem;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.eligibility_icon_img {
    width: 74 / $setFs + rem;
}

.eligibility_txt {
    font-size: 20 / $setFs + rem;
    padding-left: 30 / $setFs + rem;
}

.eligibility_num {
    width: 30 / $setFs + rem;
    height: 30 / $setFs + rem;
    border-radius: 30 / $setFs + rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18 / $setFs + rem;
    color: #fff;
    background: $txtColor;
    position: absolute;
    top: 25 / $setFs + rem;
    left: 10 / $setFs + rem;

    @media screen and (max-width: 850px) {
        width: 36 / $setFs + rem;
        height: 36 / $setFs + rem;
        font-size: 24 / $setFs + rem;
        top: 20 / $setFs + rem;
        left: 5 / $setFs + rem;
    }
}

.eligibility_item {
    width: 213 / $setFs + rem;
    height: 213 / $setFs + rem;
    border-radius: 213 / $setFs + rem;
    background: #fff;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    position: relative;

    .eligibility_txt {
        padding-left: 0;
    }
}

.apply_sec {
    background: #b9dadc;
    margin-top: -25 / $setFs + rem;
    padding-bottom: 1 / $setFs + rem;

    &::after {
        content: "";
        width: 100%;
        height: 50 / $setFs + rem;
        background: #fff;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 0;
    }

    .container {
        padding-top: 55 / $setFs + rem;
        z-index: 2;
    }

    .button_row {
        margin-top: 33 / $setFs + rem;

        @media screen and (max-width: 480px) {
            width: 80%;
            flex-direction: column;
            margin: 0 auto;
            margin-top: 50 / $setFs + rem;
        }
    }
}

.apply_bg_bottom {
    position: absolute;
    bottom: 38 / $setFs + rem;
    left: 0;
    z-index: 1;
}

.apply_note {
    font-size: 20 / $setFs + rem;
    text-align: center;
    margin-top: 32 / $setFs + rem;
}

.apply_content {
    margin-top: 32 / $setFs + rem;
}

.apply_book {
    width: 512 / $setFs + rem;
    position: relative;
    margin: 118 / $setFs + rem auto 0;
    left: -30 / $setFs + rem;

    @media screen and (max-width: 850px) {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        max-width: 45rem;
        width: 100%;
        left: auto;
        margin-top: 2rem;
    }

    @media screen and (max-width: 480px) {
        width: 90%;
    }
}

.apply_title_row {
    display: flex;
    align-items: center;
}

.apply_num {
    flex: 0 0 24 / $setFs + rem;
    width: 24 / $setFs + rem;
    height: 24 / $setFs + rem;
    border-radius: 24 / $setFs + rem;
    background: $txtColor;
    color: #fff;
    font-size: 14 / $setFs + rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10 / $setFs + rem;

    @media screen and (max-width: 850px) {
        flex: 0 0 26 / $setFs + rem;
        width: 26 / $setFs + rem;
        height: 26 / $setFs + rem;
        font-size: 16 / $setFs + rem;
    }
}

.apply_title {
    font-size: 20 / $setFs + rem;
    font-weight: bold;
    white-space: nowrap;

    @media screen and (max-width: 850px) {
        font-size: 24 / $setFs + rem;
    }

    @media screen and (max-width: 480px) {
        flex: 1;
        text-align: center;
        padding-right: 3rem;
    }
}

.apply_txt {
    white-space: nowrap;

    @media screen and (max-width: 850px) {
        font-size: 20 / $setFs + rem;
    }

    @media screen and (max-width: 480px) {
        text-align: center;
    }
}

.apply_item_foot {
    width: 33 / $setFs + rem;
    position: absolute;
    top: 100%;
    left: 50%;
    // margin-top: -1 / $setFs + rem;

    &.right {
        transform: scaleX(-1);
    }
}

.apply_item {
    width: auto;
    background: #fff;
    padding: 17 / $setFs + rem 35 / $setFs + rem;
    border-radius: 80 / $setFs + rem;
    position: absolute;
    top: 0;
    left: 0;

    @media screen and (max-width: 850px) {
        position: relative;
        top: auto !important;
        left: auto !important;
        flex: 0 0 calc(50% - 1rem);
        background: #fff;
        border-radius: 3rem;
        margin: 0.5rem;
        overflow: hidden;
    }

    @media screen and (max-width: 480px) {
        flex: 0 0 calc(70% - 1rem);
    }

    &.i1 {
        top: 170 / $setFs + rem;
        left: -133 / $setFs + rem;
    }

    &.i2 {
        top: 58 / $setFs + rem;
        left: -230 / $setFs + rem;

        .apply_item_foot {
            left: 60%;
        }
    }

    &.i3 {
        padding: 15 / $setFs + rem 35 / $setFs + rem;
        top: -77 / $setFs + rem;
        left: -134 / $setFs + rem;

        .apply_item_foot {
            left: 60%;
        }
    }

    &.i4 {
        top: -77 / $setFs + rem;
        left: 157 / $setFs + rem;

        .apply_item_foot {
            left: 45%;
        }
    }

    &.i5 {
        top: -77 / $setFs + rem;
        left: 445 / $setFs + rem;

        .apply_item_foot {
            left: 30%;
        }
    }

    &.i6 {
        top: 32 / $setFs + rem;
        left: 602 / $setFs + rem;

        .apply_item_foot {
            left: 30%;
        }
    }

    &.i7 {
        top: 140 / $setFs + rem;
        left: 497 / $setFs + rem;

        .apply_item_foot {
            left: 20%;
        }
    }

    // &.i7,
    // &.i8 {
    //     .apply_img {
    //         @media screen and (max-width: 850px) {
    //             margin-bottom: -3rem;
    //         }

    //         @media screen and (max-width: 480px) {
    //             margin-bottom: -1rem;
    //         }
    //     }
    // }

    &.i8 {
        width: 173 / $setFs + rem;
        background: transparent;
        padding: 0;
        top: 34 / $setFs + rem;
        left: 40 / $setFs + rem;

        @media screen and (max-width: 850px) {
            background: $txtColor;
            padding: 17 / $setFs + rem 35 / $setFs + rem;
        }

        .apply_8_bg {
            @media screen and (max-width: 850px) {
                display: none;
            }
        }

        // .apply_img {
        //     @media screen and (max-width: 850px) {
        //         transform: scale(1.25);
        //         margin-bottom: -3rem;
        //     }

        //     @media screen and (max-width: 480px) {
        //         transform: scale(1.1);
        //         margin-bottom: -3rem;
        //     }
        // }

        .apply_txt {
            color: #fff;
            text-align: center;
            width: 100%;
            box-sizing: border-box;
            position: absolute;
            top: 18%;
            left: 0;

            @media screen and (max-width: 850px) {
                font-size: 24 / $setFs + rem;
                position: relative;
                top: 0;
            }
        }

        .apply_icon_pdf {
            display: inline-block;
            width: 30 / $setFs + rem;
            vertical-align: middle;
            margin: 0 3 / $setFs + rem;
        }
    }
}

.apply_book_img {
    @media screen and (max-width: 850px) {
        display: none;
    }
}

// .apply_img {
//     @media screen and (max-width: 850px) {
//         margin-bottom: -1rem;
//     }
// }

// .apply_item {
//     width: 297 / $setFs + rem;
//     position: absolute;
//     top: 0;
//     left: 0;

//     @media screen and (max-width: 850px) {
//         position: relative;
//         top: auto !important;
//         left: auto !important;
//         flex: 0 0 calc(50% - 1rem);
//         background: #fff;
//         border-radius: 3rem;
//         margin: .5rem;
//         overflow: hidden;
//     }

//     @media screen and (max-width: 480px) {
//         flex: 0 0 calc(70% - 1rem);
//     }

//     &.i1 {
//         top: 170 / $setFs + rem;
//         left: -183 / $setFs + rem;
//     }

//     &.i2 {
//         top: 58 / $setFs + rem;
//         left: -280 / $setFs + rem;
//     }

//     &.i3 {
//         top: -77 / $setFs + rem;
//         left: -164 / $setFs + rem;
//     }

//     &.i4 {
//         top: -77 / $setFs + rem;
//         left: 127 / $setFs + rem;
//     }

//     &.i5 {
//         top: -77 / $setFs + rem;
//         left: 445 / $setFs + rem;
//     }

//     &.i6 {
//         top: 32 / $setFs + rem;
//         left: 552 / $setFs + rem;
//     }

//     &.i7 {
//         top: 140 / $setFs + rem;
//         left: 497 / $setFs + rem;
//     }

//     &.i8 {
//         top: 34 / $setFs + rem;
//         left: -18 / $setFs + rem;
//     }

//     &.i7,
//     &.i8 {
//         .apply_img {
//             @media screen and (max-width: 850px) {
//                 margin-bottom: -3rem;
//             }

//             @media screen and (max-width: 480px) {
//                 margin-bottom: -1rem;
//             }
//         }
//     }

//     &.i8 {
//         @media screen and (max-width: 850px) {
//             background: $txtColor;
//         }

//         .apply_img {
//             @media screen and (max-width: 850px) {
//                 transform: scale(1.25);
//                 margin-bottom: -3rem;
//             }

//             @media screen and (max-width: 480px) {
//                 transform: scale(1.1);
//                 margin-bottom: -3rem;
//             }
//         }
//     }
// }

.judge_sec {
    padding-top: 89 / $setFs + rem;
    z-index: 0;

    .container {
        @media screen and (max-width: 850px) {
            padding-bottom: 100 / $setFs + rem;
        }
    }

    .maintitle_row {
        margin-bottom: 46 / $setFs + rem;
    }
}

.judge_item {
    background: $color;
    border-top-left-radius: 30 / $setFs + rem;
    border-bottom-right-radius: 30 / $setFs + rem;
    margin-bottom: 12 / $setFs + rem;
    padding: 32 / $setFs + rem 0;
    position: relative;

    &::after {
        content: "";
        border-style: solid;
        border-width: 33 / $setFs + rem 30 / $setFs + rem 0 30 / $setFs + rem;
        border-color: $color transparent transparent transparent;
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
    }

    &.first {
        padding: 17 / $setFs + rem 0;
    }

    &:nth-child(2n + 1) {
        background: #f0f0f0;

        &::after {
            border-color: #f0f0f0 transparent transparent transparent;
        }
    }

    &:nth-child(1) {
        z-index: 5;
    }

    &:nth-child(2) {
        z-index: 4;
    }

    &:nth-child(3) {
        z-index: 3;
    }

    &:nth-child(4) {
        z-index: 2;
    }

    &:nth-child(5) {
        z-index: 1;
    }

    &:nth-child(6) {
        z-index: 0;

        &::after {
            display: none;
        }
    }
}

.judge_row {
    display: flex;
    align-items: center;

    @media screen and (max-width: 480px) {
        flex-direction: column;
        align-items: flex-start;
    }

    &.main_row {
        align-items: flex-start;
    }

    &.chart_row {
        align-items: flex-start;
    }
}

.judge_col {
    flex: 1;

    @media screen and (max-width: 480px) {
        width: 100%;
        padding: 1rem 0;
    }
}

.judge_title {
    font-size: 20 / $setFs + rem;
    font-weight: bold;
    white-space: nowrap;
    padding-left: 17 / $setFs + rem;
    position: relative;

    &::before {
        content: "";
        width: 7 / $setFs + rem;
        height: 7 / $setFs + rem;
        border-radius: 7 / $setFs + rem;
        background: $txtColor;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
    }
}

.judge_txt {
    font-size: 20 / $setFs + rem;
    padding-left: 10 / $setFs + rem;

    @media screen and (max-width: 480px) {
        padding-left: 15 / $setFs + rem;
    }

    .underline {
        text-decoration: underline;
        cursor: pointer;
    }

    span {
        color: $color3;
        font-weight: bold;

        @media screen and (max-width: 850px) {
            display: block;
        }
    }

    br {
        @media screen and (max-width: 850px) {
            display: none;
        }
    }
}

.judge_chart {
    width: 324 / $setFs + rem;
    margin-left: 0 / $setFs + rem;
    position: relative;

    @media screen and (max-width: 480px) {
        margin: 0 auto;
    }
}

.judge_chart_3_txt {
    width: 186 / $setFs + rem;
    position: absolute;
    bottom: 50%;
    right: 92%;
}

.note_sec {
    background: $color;
    padding-top: 63 / $setFs + rem;
    padding-bottom: 130 / $setFs + rem;

    .maintitle_row {
        margin-bottom: 37 / $setFs + rem;
    }
}

.note_row {
    display: flex;
    align-items: flex-start;
    padding: 5 / $setFs + rem 0;
}

.note_title {
    font-size: 18 / $setFs + rem;
    font-weight: normal;
    color: #fff;
    line-height: 30 / $setFs + rem;
    white-space: nowrap;
    background: $txtColor;
    border-radius: 50 / $setFs + rem;
    padding: 0 20 / $setFs + rem;
}

.note_txt {
    font-size: 18 / $setFs + rem;
    padding-left: 20 / $setFs + rem;
}

.note_dec_1 {
    width: 202 / $setFs + rem;
    position: absolute;
    top: 90 / $setFs + rem;
    left: 80 / $setFs + rem;

    @media screen and (max-width: 850px) {
        top: 0 / $setFs + rem;
        left: -25 / $setFs + rem;
    }
}

.note_dec_2 {
    width: 355 / $setFs + rem;
    position: absolute;
    top: -150 / $setFs + rem;
    right: 145 / $setFs + rem;

    @media screen and (max-width: 850px) {
        top: -100 / $setFs + rem;
        right: 45 / $setFs + rem;
    }
}

.lb_txt {
    font-size: 20 / $setFs + rem;
    text-align: center;
    padding: 30 / $setFs + rem 0;
}

.lb_house_top {
    position: relative;

    .maintitle_row {
        width: 100%;
        position: absolute;
        bottom: 30 / $setFs + rem;
        left: 0;

        @media screen and (max-width: 850px) {
            bottom: 0;
        }

        @media screen and (max-width: 480px) {
            bottom: -20 / $setFs + rem;
        }
    }
}

.lb_house_body {
    background: $color;
    border-bottom-left-radius: 15 / $setFs + rem;
    border-bottom-right-radius: 15 / $setFs + rem;
    padding-bottom: 92 / $setFs + rem;

    @media screen and (max-width: 950px) {
        padding-top: 50 / $setFs + rem;
    }

    @media screen and (max-width: 480px) {
        padding-top: 50 / $setFs + rem;
    }

    .container {
        width: calc(100% - #{183 / $setFs + rem});

        @media screen and (max-width: 850px) {
            $x: 60 / $setFs + rem;
            width: calc(100% - #{$x});
        }
    }

    // .maintitle_row {
    //     position: relative;
    //     margin-bottom: 30 / $setFs + rem;
    // }
}

#lb_chart {
    .lb_house_body {
        padding-top: 0;
    }

    .maintitle_row {
        position: relative;
        top: -2rem;

        @media screen and (max-width: 480px) {
            top: auto;
        }
    }
}

.lb_chart_sec {
    padding-top: 50 / $setFs + rem;
}

.lb_chart_txt {
    font-size: 21 / $setFs + rem;
    text-align: center;
    margin-bottom: 3 / $setFs + rem;

    span {
        font-size: 26 / $setFs + rem;
        font-weight: bold;
    }
}

.lb_chart_list {
    background: #fff;
    padding: 30 / $setFs + rem 0;
    padding-left: 58 / $setFs + rem;

    @media screen and (max-width: 480px) {
        padding: 30 / $setFs + rem;
    }
}

.lb_chart_li {
    font-size: 20 / $setFs + rem;
    padding: 2 / $setFs + rem 0;
    padding-left: 17 / $setFs + rem;
    position: relative;

    &::before {
        content: "";
        width: 7 / $setFs + rem;
        height: 7 / $setFs + rem;
        border-radius: 7 / $setFs + rem;
        background: $txtColor;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);

        @media screen and (max-width: 480px) {
            top: 1.1rem;
        }
    }
}

.judgeintro_sec {
    padding-top: 50 / $setFs + rem;
    padding-bottom: 50 / $setFs + rem;

    .container {
        max-width: 1440 / $setFs + rem;
    }
}

.judgeintro_list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 30 / $setFs + rem;
    margin-left: -20 / $setFs + rem;
    margin-right: -20 / $setFs + rem;

    @media screen and (max-width: 480px) {
        margin-left: -10 / $setFs + rem;
        margin-right: -10 / $setFs + rem;
    }
}

.judgeintro_li {
    flex: 0 0 25%;
    padding: 20 / $setFs + rem;
    box-sizing: border-box;

    @media screen and (max-width: 480px) {
        flex: 0 0 50%;
        padding: 10 / $setFs + rem;
    }
}

.judgeintro_item {
    height: 100%;
    position: relative;
    border: 1 / $setFs + rem solid #ddd;
    border-radius: 20 / $setFs + rem;
    padding: 35 / $setFs + rem 0 5 / $setFs + rem;
    box-sizing: border-box;

    // &:after {
    //     content: "";
    //     width: 100%;
    //     height: 60%;
    //     background: $color;
    //     border-top-left-radius: 30 / $setFs + rem;
    //     border-bottom-right-radius: 30 / $setFs + rem;
    //     position: absolute;
    //     bottom: 0;
    //     left: 0;
    //     z-index: -1;
    // }
}

.judgeintro_pic {
    width: 65%;
    margin: 0 auto;
    position: relative;

    @media screen and (max-width: 480px) {
        width: 80%;
    }

    img {
        border-radius: 200 / $setFs + rem;
    }
}

.judgeintro_caption {
    // padding: 0 35 / $setFs + rem 25 / $setFs + rem;
    // margin-top: -30 / $setFs + rem;
    padding: 35 / $setFs + rem 35 / $setFs + rem 25 / $setFs + rem;
    position: relative;
    z-index: 1;
}

.judgeintro_title_row {
    display: flex;
    justify-content: center;
}

.judgeintro_title {
    font-size: 20 / $setFs + rem;
    // color: #fff;
    // white-space: nowrap;
    // background: $txtColor;
    border-radius: 50 / $setFs + rem;
    // padding: 5 / $setFs + rem 20 / $setFs + rem;
}

.judgeintro_txt {
    text-align: center;
    margin-top: 3 / $setFs + rem;
    opacity: 0.9;
}

.vote_sec {
    background: $color;
    padding-top: 60 / $setFs + rem; //評審區塊顯示時，槓掉此行

    .container {
        width: calc(100% - #{80 / $setFs + rem});
        max-width: none;
        padding-top: 55 / $setFs + rem;

        @media screen and (max-width: 850px) {
            width: calc(100% - #{40 / $setFs + rem});
        }
    }
}

.vote_txt {
    text-align: center;
    font-size: 34 / $setFs + rem;
    margin-top: 50 / $setFs + rem;

    &.small {
        font-size: 20 / $setFs + rem;
        color: #000;
        text-align: center;
        margin-top: 25 / $setFs + rem;
    }
}

.small {
    font-size: 14 / $setFs + rem;
    text-align: center;
    margin-top: 25 / $setFs + rem;
    margin-bottom: 10 / $setFs + rem;
    opacity: .8;
}

.vote_list {
    display: flex;
    flex-wrap: wrap;
    margin-left: -20 / $setFs + rem;
    margin-right: -20 / $setFs + rem;

    @media screen and (max-width: 850px) {
        margin-left: -10 / $setFs + rem;
        margin-right: -10 / $setFs + rem;
    }
}

.vote_li {
    flex: 0 0 33.33333%;
    padding: 20 / $setFs + rem;
    box-sizing: border-box;

    @media screen and (max-width: 850px) {
        flex: 0 0 50%;
        padding: 10 / $setFs + rem;
    }

    @media screen and (max-width: 480px) {
        flex: 0 0 100%;
    }
}

.vote_item {
    background: #fff;
    border-top-left-radius: 30 / $setFs + rem;
    border-bottom-right-radius: 30 / $setFs + rem;
    margin-bottom: 12 / $setFs + rem;
    padding: 32 / $setFs + rem 32 / $setFs + rem 12 / $setFs + rem;
    position: relative;

    &:hover {
        .vote_pic {
            &::after {
                opacity: 1;
            }

            img {
                opacity: 0.1;
            }
        }
    }
}

.vote_award {
    width: 75 / $setFs + rem;
    position: absolute;
    top: 0;
    right: 0;
    margin-top: -40 / $setFs + rem;
    margin-right: -10 / $setFs + rem;
}

.vote_pic {
    position: relative;
    cursor: pointer;

    &::after {
        content: "了解更多 >>";
        font-size: 20 / $setFs + rem;
        color: #fff;
        letter-spacing: 1 / $setFs + rem;
        font-weight: normal;
        background: #9bbdbf;
        padding: 9px 30px;
        border-radius: 50px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0;
        transition: opacity 0.3s ease-out;
    }

    img {
        border-top-left-radius: 25 / $setFs + rem;
        border-bottom-right-radius: 25 / $setFs + rem;
        transition: opacity 0.3s ease-out;
    }
}

.vote_caption {
    padding-bottom: 10 / $setFs + rem;
}

.vote_org {
    span {
        background-color: #fff;
        position: relative;

        &::after {
            content: "";
            width: 64 / $setFs + rem;
            width: 20 / $setFs + rem;
            height: 1 / $setFs + rem;
            background: $txtColor;
            position: absolute;
            top: 50%;
            left: 100%;
            margin-left: 10 / $setFs + rem;
        }
    }
}

.vote_title {
    height: 3.2em;
    font-size: 20 / $setFs + rem;
    position: relative;
}

.vote_row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 12 / $setFs + rem;
}

.vote_btn_arrow {
    width: 28 / $setFs + rem;
    flex: 0 0 26 / $setFs + rem;
}

.vote_btn_txt {
    width: auto;
    flex: 0 0 auto;
    font-size: 20 / $setFs + rem;
    white-space: nowrap;
    color: #fff;
    padding-left: 10 / $setFs + rem;
    position: relative;
    top: -1 / $setFs + rem;
}

.vote_btn {
    min-width: auto;
    height: 46 / $setFs + rem;
    border-radius: 40 / $setFs + rem;
    background: $color3;
    box-shadow: 0 1 / $setFs + rem 0 $color3;
    display: flex;
    align-items: center;
    padding: 5 / $setFs + rem 27 / $setFs + rem;
    border: 1 / $setFs + rem solid darken($color3, 5%);
    border-bottom: 3 / $setFs + rem solid #fff;
    cursor: pointer;

    &:hover {
        background: #d10034;
        border: 1 / $setFs + rem solid darken(#d10034, 10%);
        border-bottom: 1 / $setFs + rem solid transparent;

        .vote_btn_txt {
            text-shadow: (-2 / $setFs + rem) (-1 / $setFs + rem) 0 darken(#d10034, 10%);
        }
    }
}

.vote_score_row {
    display: flex;
    align-items: center;
    margin-left: -10 / $setFs + rem;

    @media screen and (max-width: 850px) {
        flex-wrap: wrap;
    }
}

.vote_col {
    display: flex;
    align-items: center;
    padding: 0 7 / $setFs + rem;

    @media screen and (max-width: 850px) {
        &:nth-child(3) {
            flex: 0 0 100%;
            margin-top: 5 / $setFs + rem;
        }
    }
}

.vote_score_title {
    font-size: 0.9em;
    white-space: nowrap;
}

.vote_num {
    font-size: 1.5em;
    line-height: 1;
    padding: 0 5 / $setFs + rem;
    position: relative;
    top: -3 / $setFs + rem;
}

.vote_judge_none {
    // width: 30 / $setFs + rem;
    // height: 25 / $setFs + rem;
    // background: #ddd;
    // border-radius: 3 / $setFs + rem;
    margin-left: 5 / $setFs + rem;
}

.vote_order {
    width: 27 / $setFs + rem;
    height: 27 / $setFs + rem;
    border-radius: 30 / $setFs + rem;
    border: 1 / $setFs + rem solid #ddd;
    font-size: 1.5em;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 7 / $setFs + rem;
    padding-right: 2 / $setFs + rem;
    padding-bottom: 2 / $setFs + rem;
    box-sizing: border-box;
    position: relative;
    top: -3 / $setFs + rem;
}

#lb_vote_login {
    .lb_txt {
        padding-top: 0;
    }
}

.lb_center {
    min-width: 400 / $setFs + rem;
    white-space: nowrap;
    background: $color;
    border-radius: 15 / $setFs + rem;
    padding: 80 / $setFs + rem 60 / $setFs + rem;
    position: relative;

    @media screen and (max-width: 480px) {
        white-space: unset;
        padding: 60 / $setFs + rem 20 / $setFs + rem;
    }

    .btn_close {
        top: -50 / $setFs + rem;
        right: -50 / $setFs + rem;

        @media screen and (max-width: 480px) {
            top: -60 / $setFs + rem;
            right: 0 / $setFs + rem;
        }
    }
}

#lb_vote_content {
    .btn_close {
        top: 0;
        right: -30 / $setFs + rem;

        @media screen and (max-width: 950px) {
            top: 0.7rem;
            right: 3.2rem;
        }

        @media screen and (max-width: 480px) {
            top: 8 / $setFs + rem;
            right: 8 / $setFs + rem;
        }
    }

    .lb_board {
        @media screen and (max-width: 950px) {
            width: calc(100% - #{20 / $setFs + rem});
            max-width: none;
        }
    }

    .lb_house_body {
        border-radius: 15 / $setFs + rem;
        padding-top: 92 / $setFs + rem;
    }
}

.lb_swiper {
    padding: 0 70 / $setFs + rem 25 / $setFs + rem;
    position: relative;
    margin-bottom: 20 / $setFs + rem;

    @media screen and (max-width: 480px) {
        padding: 0 0 20 / $setFs + rem;
    }

    .swiper-slide {
        padding: 10 / $setFs + rem;
        box-sizing: border-box;
    }

    .swiper-button-prev,
    .swiper-button-next {
        width: 60 / $setFs + rem;
        height: 60 / $setFs + rem;
        border-radius: 60 / $setFs + rem;
        background: #87c3f2;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: opacity 0.3s ease-out;

        &:after {
            font-size: 0;
        }

        &:hover {
            opacity: 1;
        }

        @media screen and (max-width: 950px) {
            opacity: 1;
        }

        @media screen and (max-width: 480px) {
            width: 50 / $setFs + rem;
            height: 50 / $setFs + rem;
            margin-top: -25 / $setFs + rem;
        }

        &.swiper-button-disabled {
            opacity: 0.3;
        }

        i {
            font-size: 30 / $setFs + rem;
            color: #fff;
        }
    }

    .swiper-button-prev {
        left: 0;

        @media screen and (max-width: 480px) {
            left: -1rem;
        }
    }

    .swiper-button-next {
        right: 0;

        @media screen and (max-width: 480px) {
            right: -1rem;
        }
    }

    .swiper-pagination {
        width: auto !important;
        top: auto !important;
        bottom: 0 !important;
        left: 50% !important;
        transform: translateX(-50%);
    }

    .swiper-pagination-bullet {
        width: 8 / $setFs + rem;
        height: 8 / $setFs + rem;
        border-radius: 8 / $setFs + rem;
        border: 0;
        background-color: #222;
        box-sizing: border-box;
        margin: 0 10 / $setFs + rem !important;
        opacity: 0.3;
        transition: all 0.3s ease-out;
    }

    .swiper-pagination-bullet-active {
        background-color: $color2;
        opacity: 1;
    }
}

.lb_img {
    padding: 10 / $setFs + rem;
    background: #fff;
    box-sizing: border-box;
    box-shadow: 2 / $setFs + rem 3 / $setFs + rem 5 / $setFs + rem rgba(#000, 0.25);
}

.lb_org {
    span {
        font-size: 20 / $setFs + rem;
        background-color: $color;
        position: relative;

        &::after {
            content: "";
            width: 100 / $setFs + rem;
            width: 20 / $setFs + rem;
            height: 1 / $setFs + rem;
            background: $txtColor;
            position: absolute;
            top: 50%;
            left: 100%;
            margin-left: 10 / $setFs + rem;
        }
    }
}

.lb_title {
    font-size: 34 / $setFs + rem;
    font-weight: bold;
    margin-bottom: 10 / $setFs + rem;
}

.lb_txt_sec {
    padding: 20 / $setFs + rem 0;

    .lb_txt {
        text-align: left;
        padding: 15 / $setFs + rem 0;
    }
}

.lb_subtitle_row {
    display: flex;
}

.lb_subtitle {
    font-size: 22 / $setFs + rem;
    color: #fff;
    letter-spacing: 2 / $setFs + rem;
    background: #333;
    line-height: 40 / $setFs + rem;
    padding: 0 34 / $setFs + rem;
    position: relative;
    z-index: 1;

    &::before,
    &::after {
        content: "";
        border-style: solid;
        border-width: 21 / $setFs + rem 5 / $setFs + rem;
        position: absolute;
        top: 0;
    }

    &::before {
        border-color: transparent #333 #333 transparent;
        right: 100%;
    }

    &::after {
        border-color: #333 transparent transparent #333;
        left: 100%;
    }
}

.lb_download_row {
    display: flex;
    justify-content: center;
    margin-top: 10 / $setFs + rem;
}

.lb_button_row {
    display: flex;
    justify-content: center;
    margin-top: 50 / $setFs + rem;

    .vote_btn {
        height: 53 / $setFs + rem;
        padding: 6 / $setFs + rem 40 / $setFs + rem;
    }

    .vote_btn_txt {
        font-size: 23 / $setFs + rem;
        top: -1 / $setFs + rem;
    }

    .vote_btn_arrow {
        width: 30 / $setFs + rem;
        flex: 0 0 30 / $setFs + rem;
    }
}

.lb_download_link {
    display: flex;
    align-items: center;

    &:hover {
        .lb_download_txt {
            text-decoration: none;
        }
    }

    .fa-file-pdf {
        font-size: 38 / $setFs + rem;
        margin-right: 3 / $setFs + rem;
    }

    .fa-arrow-up-right-from-square {
        font-size: 18 / $setFs + rem;
        margin-left: 1 / $setFs + rem;
        position: relative;
        bottom: -3 / $setFs + rem;
    }
}

.lb_download_txt {
    font-size: 22 / $setFs + rem;
    text-decoration: underline;
    transition: text-decoration 0.1s ease-out;
}

#lb_vote_msg {
    .auth_row {
        flex-direction: column;
        justify-content: center;
        font-size: 16 / $setFs + rem;
        margin-bottom: 10 / $setFs + rem;
    }
}

#lb_selected {
    display: flex;
    opacity: 1;

    .maintitle_row {
        // color: #fff;
        bottom: 0;
    }

    // .maintitle {
    //     color: #fff;

    //     &::before {
    //         background-image: url("../../img/title_deco_w.png");
    //     }

    //     &::after {
    //         background-image: url("../../img/title_deco_line_w.png");
    //     }
    // }

    .lb_house_body {
        background-color: $color;
        padding-top: 25 / $setFs + rem;
        padding-bottom: 50 / $setFs + rem;
    }

    .small {
        // color: #fff;
        margin-top: 0;
    }
}

.selected_list {
    max-width: 760 / $setFs + rem;
    background-color: $color;
    background-color: #fff;
    padding: 20 / $setFs + rem 80 / $setFs + rem;
    box-sizing: border-box;
    border-radius: 20 / $setFs + rem;
    margin: 0 auto;
    margin-top: 30 / $setFs + rem;

    @media screen and (max-width: 480px) {
        padding: 20 / $setFs + rem 2rem;
    }
}

.selected_li {
    font-size: 20 / $setFs + rem;
    padding: 7 / $setFs + rem 0;
    padding-left: 17 / $setFs + rem;
    position: relative;

    &::before {
        content: "";
        width: 7 / $setFs + rem;
        height: 7 / $setFs + rem;
        border-radius: 7 / $setFs + rem;
        background: $txtColor;
        position: absolute;
        top: 23 / $setFs + rem;
        left: 0;
    }
}