.form_row {
    width: 100%;
    display: flex;
    // margin-bottom: 0.75rem;
    padding: 0.375rem 0;

    @media screen and (max-width: 480px) {
        display: block;
        padding: 0.5rem 0;
    }

    &:last-child {
        margin-bottom: 0;
    }
}

.form_left {
    flex: 0 0 150 / $setFs + rem;
    text-align: right;
    padding-right: 1.5rem;
    position: relative;

    @media screen and (max-width: 480px) {
        font-weight: bold;
        text-align: left;
        padding-bottom: 0.3rem;
    }
}

.form_right {
    flex: 1;
    min-width: 0;
    position: relative;
}


.column_row {
    display: flex;
    flex-wrap: wrap;
    margin-left: -10 / $setFs + rem;
    margin-right: -10 / $setFs + rem;

    @media screen and (max-width: 480px) {
        display: block;
    }

    &.pv {
        padding-top: 10 / $setFs + rem;
        padding-bottom: 10 / $setFs + rem;
    }
}

.column_col {
    min-width: 0;
    flex: 1;
    padding: 0 10 / $setFs + rem;
    box-sizing: border-box;
    position: relative;

    &.col_3 {
        min-width: 0;
        flex: 0 0 33.3333%;
    }

    &.col_auto {
        flex: 0 0 auto;
    }
}

.form_text {
    display: block;
    font-size: 18 / $setFs + rem;
    line-height: 1.7;
    margin-top: 8 / $setFs + rem;
}

.form_confirm_row {
    // display: flex;
    padding: 0.375rem 0;

    a {
        display: inline;

        &::before {
            content: '';
            width: 100%;
            height: 1 / $setFs + rem;
            background: $txtColor;
            position: absolute;
            bottom: -4 / $setFs + rem;
            left: 0;
            transition: transform .1s ease-out;
        }

        &:hover {
            &::before {
                transform: scaleX(0);
            }
        }
    }
}

.form_submit_btn_row {
    margin-top: 30 / $setFs + rem;
    margin-bottom: 50 / $setFs + rem;

    @media screen and (max-width: 480px) {
        display: block;
    }
}

.form_submit_btn {
    @extend .button_link;
    width: 100%;
    height: 65 / $setFs + rem;
    cursor: pointer;

    &.disabled,
    &:disabled {
        opacity: .5;
    }

    .icon_loading {
        width: 10 / $setFs + rem;
        height: 10 / $setFs + rem;
        border-color: #fff;
        border-left-color: transparent;
        margin-left: 15 / $setFs + rem;
        margin-right: 0;
        margin-top: 8 / $setFs + rem;
    }

    i {
        font-size: 25 / $setFs + rem;
        margin-right: 10 / $setFs + rem;
    }
}

.form_pic_view {
    width: 200 / $setFs + rem;
    background: #fff;
    position: relative;

    @media screen and (max-width: 480px) {
        width: 100%;
        margin: 0 auto;
    }

    .img {
        object-fit: contain;
    }
}

.file_upload_group {
    display: flex;
    align-items: flex-end;
    margin-left: -7 / $setFs + rem;
    margin-right: -7 / $setFs + rem;

    @media screen and (max-width: 480px) {
        padding-bottom: .5rem;
    }

    i {
        font-size: 40 / $setFs + rem;

        @media screen and (max-width: 480px) {
            font-size: 50 / $setFs + rem;
        }
    }
}

.file_upload_col {
    padding: 0 7 / $setFs + rem;
}

.file_row,
.pic_row {
    .buttonStyle {
        @media screen and (max-width: 480px) {
            width: 100%;
        }
    }
}

.pic_row {
    .file_upload_group {
        @media screen and (max-width: 480px) {
            display: block;
        }
    }

    .file_name {

        @media screen and (max-width: 480px) {
            text-align: center;
            margin-top: 0.3rem;
        }
    }
}

.file_name {
    font-size: 17 / $setFs + rem;
    line-height: 1.2;

    @media screen and (max-width: 480px) {
        font-size: 20 / $setFs + rem;
    }
}

.fa-file-pdf {
    font-size: 32 / $setFs + rem;
    color: darken(#f40f02, 50%);
}

.align_center {
    display: flex;
    align-items: center;
}

.icon_loading {
    width: 15 / $setFs + rem;
    height: 15 / $setFs + rem;
    border-radius: 15 / $setFs + rem;
    border: 3px solid rgba($txtColor, .7);
    border-left-color: transparent;
    margin: 0 auto .5rem;
    animation: icon_loading 1.5s linear infinite;
}

@keyframes icon_loading {
    100% {
        transform: rotate(360deg);
    }
}

.result_row {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30 / $setFs + rem;
}

.form_submit_result {
    font-size: 20 / $setFs + rem;
    line-height: 1.6;

    i {
        font-size: 30 / $setFs + rem;
        position: relative;
        bottom: -3 / $setFs + rem;
    }
}

.icon_require {
    color: $color3;
    font-size: 24 / $setFs + rem;
    line-height: 1;
    position: absolute;
    top: 16 / $setFs + rem;
    right: 7 / $setFs + rem;

    @media screen and (max-width: 480px) {
        right: auto;
        top: 8 / $setFs + rem;
        left: -1rem;
    }
}

.member_delete_row {
    display: flex;
    justify-content: flex-end;

    a {
        font-size: 18 / $setFs + rem;
        color: #999;

        &::before {
            content: '';
            width: 100%;
            height: 1px;
            background: #999;
            position: absolute;
            bottom: 0;
            left: 0;
            opacity: .7;
            transition: opacity .1s;
        }

        &:hover {
            &::before {
                opacity: 0;
            }
        }
    }
}

.form_notice {
    .icon_require {
        display: inline-block;
        position: relative;
        top: 2 / $setFs + rem;
        right: auto;
        margin-right: 2 / $setFs + rem;

        @media screen and (max-width: 480px) {
            left: auto;
        }
    }
}