.maintitle_row {
    display: flex;
    justify-content: center;
}

.maintitle {
    font-size: 50 / $setFs + rem;
    font-weight: normal;
    line-height: 1.45;
    letter-spacing: 10 / $setFs + rem;
    text-indent: 10 / $setFs + rem;
    position: relative;

    @media screen and (max-width: 480px) {
        font-size: 40 / $setFs + rem;
    }

    &::before {
        content: '';
        width: 87px; //勿更改單位
        height: 50px; //勿更改單位
        background-image: url('../../img/title_deco_b.png');
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        right: 100%;
        bottom: -19px; //勿更改單位
        margin-right: -14px; //勿更改單位
    }

    &::after {
        content: '';
        width: 100%;
        height: 3px; //勿更改單位
        background-image: url('../../img/title_deco_line_b.png');
        background-size: auto 100%;
        background-repeat: repeat-x;
        position: absolute;
        bottom: 0;
        left: 13px; //勿更改單位
    }
}