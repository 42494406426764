.header {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5;

    .container {
        max-width: none;
        z-index: 5;
    }

    .accesskey {
        top: 50%;
        transform: translateY(-50%);
    }

    .auth_row {
        margin-right: 20 / $setFs + rem;
    }

    .member_name {
        @media screen and (max-width: 480px) {
            display: none;
        }
    }
}

.header_bg {
    width: 100%;
    height: 84 / $setFs + rem;
    background: #fff;
    box-shadow: 0 2 / $setFs + rem 5 / $setFs + rem rgba(#000, .25);
    position: absolute;
    top: 0;
    left: 0;
}


.header_row {
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 1;
    padding-top: 19 / $setFs + rem;
}

.header_right {
    display: flex;
    align-items: center;
}

.mainlogo {
    width: 410 / $setFs + rem;
    margin-left: 32 / $setFs + rem;

    @media screen and (max-width: 850px) {
        margin-left: 0;
    }
}

.mainlogo_link {
    @include acc;

    &.active {
        opacity: 0;
    }
}

.hamburger {
    width: 50 / $setFs + rem;
    height: 50 / $setFs + rem;
}

.hamburger_link {
    height: 100%;
    border-radius: 50 / $setFs + rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bolder;
    font-size: 14 / $setFs + rem;
    background: #fff;
    transition: background .2s;

    &.active {
        background: $color2;
    }
}

.hamburger_bar {
    width: 25 / $setFs + rem;
    height: 3 / $setFs + rem;
    background: #5e92cd;
    position: absolute;
    transition: all .4s ease-out;

    @media screen and (max-width: 850px) {
        width: 50 / $setFs + rem;
    }

    &:before,
    &:after {
        content: "";
        display: block;
        width: 100%;
        height: 3 / $setFs + rem;
        background: #5e92cd;
        position: absolute;
        right: 0;
        top: 50%;
        margin-top: -1.5 / $setFs + rem;
        transition: all .4s ease-out;
    }


    &:before {
        transform: translateY(#{-7 / $setFs + rem});

        @media screen and (max-width: 850px) {
            transform: translateY(#{-10 / $setFs + rem});
        }
    }

    &:after {
        transform: translateY(#{7 / $setFs + rem});

        @media screen and (max-width: 850px) {
            transform: translateY(#{10 / $setFs + rem});
        }
    }

    &.active {
        background: transparent;

        &:before {
            background: #fff;
            transform: translateY(0) rotate(135deg);
        }

        &:after {
            background: #fff;
            transform: translateY(0) rotate(-135deg);
        }
    }
}

.nav_bg {
    width: 100vw;
    height: 100vh;
    background: rgba(#000, .8);
    opacity: 0;
    display: none;
    position: absolute;
    top: 0;
    right: 0;
}

.nav {
    width: 495 / $setFs + rem;
    height: 100vh;
    background-image: url('../../img/nav_bg.png');
    background-size: cover;
    background-position: left bottom;
    position: fixed;
    top: 0;
    right: 0;
    transform: translateX(#{495 / $setFs + rem});
    transition: transform .4s cubic-bezier(0.25, 1, 0.5, 1);

    @media screen and (max-width: 850px) {
        width: 80%;
        transform: translateX(80vw);
    }

    @media screen and (max-width: 480px) {
        width: 100%;
        transform: translateX(100vw);
    }

    &.active {
        transform: translateX(0);
    }
}

.nav_list {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding-left: 140 / $setFs + rem;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;

    &::-webkit-scrollbar {
        width: 3 / $setFs + rem;
    }

    &::-webkit-scrollbar-track {
        border-radius: 3 / $setFs + rem;
        background: rgba(0, 0, 0, 0);
    }

    &::-webkit-scrollbar-thumb {
        background: rgba(0, 0, 0, 0.1);
    }
}

.nav_li {
    padding: 17 / $setFs + rem 0;
}

.nav_link {
    width: 230 / $setFs + rem;
    font-size: 18 / $setFs + rem;
    color: #000;
    line-height: 50 / $setFs + rem;
    text-align: center;
    border-radius: 50 / $setFs + rem;
    background: transparent;
    margin: 0 auto;
    transition: background 0.1s ease-out;

    @media screen and (max-width: 850px) {
        width: 15rem;
        font-size: 1.6rem;
    }

    &:before {
        content: '';
        width: 97 / $setFs + rem;
        height: 53 / $setFs + rem;
        background-image: url('../../img/paperairplane.png');
        background-size: contain;
        background-position: left top;
        opacity: 0;
        position: absolute;
        top: 50%;
        right: 100%;
        margin-top: -14 / $setFs + rem;
        margin-right: -35 / $setFs + rem;
        transition: opacity 0.2s ease-out;
    }

    &:hover {
        color: #fff;
        background: $color2;

        &:before {
            opacity: 1;
        }
    }
}


.subnav_box {
    padding-top: 25 / $setFs + rem;

    @media screen and (max-width: 850px) {
        padding: 0 0 30 / $setFs + rem;

        &:after {
            content: '';
            width: 40 / $setFs + rem;
            height: 1 / $setFs + rem;
            background: #6a6a6a;
            opacity: .7;
            position: absolute;
            left: 50%;
            bottom: 10 / $setFs + rem;
            transform: translateX(-50%);
        }
    }
}


.subnav_link {
    padding: 9 / $setFs + rem 0;

    &.active,
    &:focus,
    &:hover {
        @media screen and (min-width: 851px) {
            color: lighten($color, 5%);
        }
    }
}

.header_btn {
    margin-right: 20 / $setFs + rem;

    @media screen and (max-width: 480px) {
        display: none !important;
    }
}

.header_btn_link {
    font-size: 22 / $setFs + rem;
    color: #fff;
    letter-spacing: 2 / $setFs + rem;
    line-height: 46 / $setFs + rem;
    border: 2 / $setFs + rem solid #fff;
    background: #af323a;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 40 / $setFs + rem;

    @media screen and (max-width: 480px) {
        font-size: 18 / $setFs + rem;
        line-height: 40 / $setFs + rem;
    }

    &:hover {
        color: #fff;
        background: lighten(#af323a, 10%);
    }
}