.auth_row {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-left: -5 / $setFs + rem;
    margin-right: -5 / $setFs + rem;
}

.auth_col {
    padding: 0 5 / $setFs + rem;
}

.member_avatar {
    width: 40 / $setFs + rem;
    border-radius: 40 / $setFs + rem;
    display: flex;
    align-items: center;
    justify-content: baseline;
    color: #999;
    font-size: 30 / $setFs + rem;
}

.member_loading {
    width: 40 / $setFs + rem;
    height: 40 / $setFs + rem;
    display: flex;
    align-items: center;
    justify-content: center;

    .icon_loading {
        margin: 0;
    }
}