$alertColor: rgba(#c9311c, 0.1);

.labelStyle {
    font-size: 20 / $setFs + rem;
    font-family: $font;
    color: $txtColor;
    line-height: 60 / $setFs + rem;
    word-break: break-all;

    @media screen and (max-width: 480px) {
        line-height: 1.5;
        padding: .3rem 0;
    }
}

.inputStyle {
    width: 100%;
    border: 0;
    border-bottom: 1 / $setFs + rem solid $txtColor;
    border-radius: 0;
    font-size: 20 / $setFs + rem;
    font-family: $font;
    color: $txtColor;
    padding: 15 / $setFs + rem 15 / $setFs + rem;
    line-height: 1.3;
    box-shadow: none;
    background: transparent;
    appearance: none;
    box-sizing: border-box;
    display: block;

    @media screen and (max-width: 480px) {
        border: 0;
        background: #fff;
    }

    &::-webkit-input-placeholder {
        color: rgba(0, 0, 0, 0.4);
    }

    &::-moz-placeholder {
        color: rgba(0, 0, 0, 0.4);
    }

    &:-ms-input-placeholder {
        color: rgba(0, 0, 0, 0.4);
    }

    &:-moz-placeholder {
        color: rgba(0, 0, 0, 0.4);
    }

    &.alert {
        background: lighten(#c9311c, 50%);
    }

    &.otherTxt {
        width: auto;
        margin-left: 10 / $setFs + rem;
    }

    &[disabled] {
        // border-color: #fff;
    }
}

.textareaStyle {
    width: 100%;
    border: 0;
    border-radius: 0;
    font-size: 20 / $setFs + rem;
    font-family: $font;
    color: $txtColor;
    padding: 15 / $setFs + rem 15 / $setFs + rem;
    line-height: 1.3;
    box-shadow: none;
    background: #fff;
    appearance: none;
    box-sizing: border-box;
    display: block;

    &::-webkit-input-placeholder {
        color: rgba(0, 0, 0, 0.4);
    }

    &::-moz-placeholder {
        color: rgba(0, 0, 0, 0.4);
    }

    &:-ms-input-placeholder {
        color: rgba(0, 0, 0, 0.4);
    }

    &:-moz-placeholder {
        color: rgba(0, 0, 0, 0.4);
    }

    &.alert {
        background: lighten(#c9311c, 50%);
    }
}

.selectStyle {
    width: 100%;
    min-width: 197 / $setFs + rem;
    border: 1 / $setFs + rem solid rgba(0, 0, 0, 0.4);
    box-sizing: border-box;
    background-color: #fff;
    font-family: $font;
    font-size: 16 / $setFs + rem;
    padding: 8 / $setFs + rem 10 / $setFs + rem;
    padding-right: 28 / $setFs + rem;
    color: $txtColor;
    border-radius: 0;
    line-height: 1.3;
    box-sizing: border-box;
    appearance: none;
    background: url("../../img/icon_arrow_select_gray.png") no-repeat calc(100% - 10px) center;
    background-size: 10 / $setFs + rem auto;
    background-color: #fff;

    &.alert {
        background: lighten(#c9311c, 50%);
    }

    &[disabled] {
        background-color: rgba(255, 255, 255, 0);
        appearance: none;
    }
}

.input_group {
    display: flex;
    align-items: center;
}

.radioStyle {
    display: block;
    flex: 0 0 32 / $setFs + rem;
    width: 32 / $setFs + rem;
    height: 32 / $setFs + rem;
    border: 0;
    border-radius: 32 / $setFs + rem;
    background: #fff;
    appearance: none;
    position: relative;
    // top: 3 / $setFs + rem;
    margin: 0;
    margin-right: 7 / $setFs + rem;

    @media screen and (max-width: 480px) {
        width: 30 / $setFs + rem;
        height: 30 / $setFs + rem;
    }

    &:checked:after {
        content: "";
        width: 14 / $setFs + rem;
        height: 14 / $setFs + rem;
        background: $txtColor;
        border-radius: 14 / $setFs + rem;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -7 / $setFs + rem;
        margin-top: -7 / $setFs + rem;

        @media screen and (max-width: 480px) {
            // width: 8 / $setFs + rem;
            // height: 8 / $setFs + rem;
            // margin-left: -4 / $setFs + rem;
            // margin-top: -4 / $setFs + rem;
            // border-radius: 8 / $setFs + rem;
        }
    }

    &.alert {
        background: lighten(#c9311c, 50%);
    }
}

.checkboxStyle {
    @extend .radioStyle;
    border-radius: 0;
    // top: 1 / $setFs + rem;

    &:checked:after {
        border-radius: 0;
    }
}

.buttonStyle {
    min-width: 170 / $setFs + rem;
    height: 46 / $setFs + rem;
    color: #fff;
    font-size: 20 / $setFs + rem;
    letter-spacing: 1px;
    background: $txtColor;
    border: 0;
    box-shadow: none;
    appearance: none;
    padding: 0 30 / $setFs + rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;

    &:hover {
        background: lighten($txtColor, 10%);
    }

    &[disabled] {
        // background: lighten($txtColor, 10%);
        // cursor: auto;
        display: none;
    }

    &.alert {
        background: lighten(#c9311c, 30%);
    }

    &.facebook_btn {
        @include buttonApp(#3b5998);

        &:hover {
            @include buttonApp_hvr(#3b5998);
        }
    }

    &.google_btn {
        @include buttonApp(#c9311c);

        &:hover {
            @include buttonApp_hvr(#c9311c);
        }
    }

    .fa-brands {
        margin-right: 5 / $setFs + rem;
    }
}

.fileinputStyle {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
}