$color: #dcebec;
$color2: #87c3f2;
// $color3: #d10034;
$color3: #af323a;
$setFs: 16;
$txtColor: #333;
$font: "Lato",
"Noto Sans TC",
"微軟正黑體",
"蘋果儷黑體",
Arial,
sans-serif,
Verdana;
$font1: $font;


@mixin word_limit($line: 2) {
    display: -webkit-box;
    -webkit-line-clamp: $line;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

@mixin _w {
    @media screen and (min-width: 769px) {
        &:after {
            border-color: #fff;
        }
    }
}

@mixin acc {
    @media screen and (min-width: 769px) {
        &:after {
            content: "";
            width: calc(100% + 2px);
            height: calc(100% + 2px);
            border: 1px dotted transparent;
            border-radius: 3 / $setFs + rem;
            box-sizing: border-box;
            position: absolute;
            top: -1px;
            left: -1px;
            z-index: 5;
            opacity: 0;
            transition: opacity 0.1s ease-out;
        }

        &:focus {
            &:after {
                opacity: 1;
            }
        }
    }
}

@mixin acc_before {
    @media screen and (min-width: 769px) {
        &:before {
            content: "";
            width: calc(100% + 2px);
            height: calc(100% + 2px);
            border: 1px dotted transparent;
            border-radius: 3 / $setFs + rem;
            box-sizing: border-box;
            position: absolute;
            top: -1px;
            left: -1px;
            z-index: 5;
            opacity: 0;
            transition: opacity 0.1s ease-out;
        }

        &:focus {
            &:before {
                opacity: 1;
            }
        }
    }
}

@mixin acc_w {
    @include acc;
    @include _w;
}

@mixin acc_before_w {
    @include acc_before;
    @include _w;
}


@mixin clear {
    clear: both;
}

@mixin clearfix {
    &:before {
        content: "";
        display: table;
    }

    &:after {
        content: "";
        display: table;
        clear: both;
    }
}


@mixin inblock($spacing: 0) {
    font-size: 0;
    letter-spacing: 0;

    >* {
        display: inline-block;
        vertical-align: top;
        letter-spacing: $spacing / $setFs + rem;
    }
}

@mixin buttonApp($mainColor) {
    color: #fff;
    text-shadow: 0px 0px 0 darken($mainColor, 0%);
    box-sizing: border-box;
    background: $mainColor;
    background: lighten($mainColor, 3%);
    border: 1px solid darken($mainColor, 1%);
    border-right: 1px solid darken($mainColor, 10%);
    border-bottom: 2px solid darken($mainColor, 15%);
    box-shadow: 1px 1px 4px rgba(darken($mainColor, 5%), .8);
    border-radius: 1px;
    transition: all 0.05s ease-out;
}

@mixin buttonApp_hvr($mainColor) {
    color: lighten($mainColor, 40%);
    text-shadow: -1px -1px 0 darken($mainColor, 22%);
    background: $mainColor;
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-bottom: 2px solid transparent;
    box-shadow: 1px 1px 2px rgba(darken($mainColor, 5%), .8);
    transition: all 0.05s linear;
}