.button_row {
    display: flex;
    justify-content: center;
}

.button_col {
    padding: 0 15 / $setFs + rem;

    @media screen and (max-width: 480px) {
        padding: 0.6375rem 0;
    }
}

.button_link {
    min-width: 252 / $setFs + rem;
    height: 70 / $setFs + rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20 / $setFs + rem;
    color: #fff;
    letter-spacing: 1 / $setFs + rem;
    box-sizing: border-box;
    background: $txtColor;
    appearance: none;
    border: 1 / $setFs + rem solid $txtColor;
    border-bottom: 5 / $setFs + rem solid #fff;
    box-shadow: 0 1 / $setFs + rem 0 $txtColor;
    border-radius: 70 / $setFs + rem;
    transition: all 0.05s ease-out;

    @media screen and (max-width: 480px) {
        font-size: 28 / $setFs + rem;
        height: 90 / $setFs + rem;
    }

    &:hover {
        color: #fff;
        text-shadow: -1 / $setFs + rem -1 / $setFs + rem 0 darken($txtColor, 22%);
        background: lighten($txtColor, 15%);
        border: 1 / $setFs + rem solid lighten($txtColor, 10%);
        border-bottom: 1 / $setFs + rem solid transparent;
        transition: all 0.05s linear;
    }
}

.signup_btn {
    background: $color3;
    box-shadow: 0 1 / $setFs + rem 0 $color3;

    &:hover {
        text-shadow: -1 / $setFs + rem -1 / $setFs + rem 0 darken($color3, 10%);
        background: lighten($color3, 15%);
        border: 1 / $setFs + rem solid lighten($color3, 10%);
        border-bottom: 1 / $setFs + rem solid transparent;
    }
}

.icon_pen {
    width: 30 / $setFs + rem;
    margin-right: 9 / $setFs + rem;
    margin-left: -20 / $setFs + rem;
}

.icon_book {
    width: 35 / $setFs + rem;
    margin-right: 8 / $setFs + rem;
}
